.downloads-wrapper {
  position: fixed;
  bottom: 0;
  right: 20px;
  height: 278px;
  width: 350px;
  z-index: 1;
  background-color: var(--white);
  box-shadow: 0px 15px 35px rgba(132, 145, 155, 0.45);
  border-radius: 12px 12px 0px 0px;
  transform: translate(0, 0);
  animation: transform 200ms cubic-bezier(0.55, 0.09, 0.68, 0.53);
}

.downloads-wrapper.minimized {
  transform: translate(0, 223px);
}

.downloads-header {
  padding: 13px;
  border-bottom: 1px solid var(--gray-100);
  display: flex;
  align-items: center;
}

.download-icon {
  color: var(--grey-03);
}

.download-items {
  padding: 0 9px;
  overflow: auto;
  max-height: 223px;
}

.downloads-clearall {
  color: var(--red);
  padding: 2px;
  border: 1px solid var(--gray-500);
  border-radius: 100px;
}

.downloads-clearall-icon {
  font-size: 18px;
}
