.dropdownContainer {
  position: relative;
}

.dropdownButton {
  position: relative;
  width: 100%;
  border: 1px solid var(--blue-vivid-300);
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 12.5px;
  font-weight: 500;
  color: var(--blue-vivid-300);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.dropdownButtonText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transitionLeave {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 300ms;
}

.transitionLeaveFrom {
  opacity: 1;
}

.transitionLeaveTo {
  opacity: 0;
}

.listBoxOptions {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  margin-top: 4px;
  max-height: 240px;
  overflow: auto;
  width: max-content;
  border-radius: 4px;
  color: var(--gray-700);
  background-color: white;
  padding: 4px 0;
  font-size: 13px;
  z-index: 10;
  filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  border: 1px solid var(--gray-100);
}

.listBoxOption {
  padding: 4px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 13px;
  width: 100%;
}

/* listbox hover */
.listBoxOption:hover {
  background-color: var(--blue-vivid-100);
}
