.leadersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 15px;
}

.leadersGrid.horizontal {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.leadershipSection {
  border-radius: 6px;
  width: auto;
}

.title {
  margin-top: 2px;
}
