.datePicker {
  border: 1px solid var(--gray-400);
  border-radius: 4px;
}

.datePicker input {
  border: 0;
  width: 5rem;
  background-color: transparent;
}

.datePicker :global(.react-datepicker-popper) {
  z-index: 20;
}

.dateIcon {
  cursor: default;
}

.startDate input {
  text-align: right;
}
