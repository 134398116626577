.leadersGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
  gap: 10px;
}

.image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.info {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.name,
.title {
  line-height: 1.2em;
}

.title {
  margin-top: 2px;
}
