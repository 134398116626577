.switchBackground {
  width: 53px;
  height: 24px;
  border: 2px solid transparent;
  border-radius: 9999px;
  transition: background 0.25s ease 0s;
}

.switchHandle {
  width: 17px;
  height: 17px;
  box-shadow: rgb(0 0 0 / 60%) 0px 1px 5px;
  transform: translateX(2px);
  transition: background-color 0.25s ease 0s, transform 0.25s ease 0s, box-shadow 0.15s ease 0s;
}

.switchBackground:focus-visible .switchHandle {
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 1px 10px;
}

.checked {
  transform: translateX(30px);
}
