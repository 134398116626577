.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #869298;
  background-color: #ced4da;
  border-radius: 6px;
  text-align: center;
  padding: 0rem 1rem;
}
