:global([role='tablist'][aria-orientation='horizontal']) {
  display: flex;
}

.tab {
  display: flex;
  align-items: center;
  border: 0;
  transition: 0.3s ease-in-out;
  height: 42px;
  padding: 0 14px;
  margin: 0 5px;
  color: var(--primary);
  font-weight: 500;
  line-height: 1.2;
}

.tab.underline {
  border-bottom: 3px solid transparent;
}

.selected,
:global([aria-selected='true']).tab,
:global([role='tab'][aria-current='page']).tab {
  color: var(--primary);
}

.selected.underline,
:global([aria-selected='true']).tab.underline,
:global([role='tab'][aria-current='page']).tab.underline {
  border-bottom: 3px solid var(--primary);
}
