.wrapper {
  padding: 23px;
  border-left: 1px solid var(--gray-200);
  border-right: 1px solid var(--gray-200);
}

.borderColor {
  border-color: var(--blue-001) !important;
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 10px;
  flex-grow: 1;
  justify-items: center;
}

.stat {
  max-width: 220px;
}

.statContent {
  margin-left: 12px;
}

.partnerLogo {
  max-width: 190px;
  max-height: 35px;
  aspect-ratio: auto;
}

.logoContainer {
  gap: 10px;
}

@media (min-width: 1300px) {
  .statsGrid {
    justify-items: start;
  }
}
