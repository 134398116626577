.popElementWrapper {
  position: relative;
  cursor: pointer;
}

.popElement {
  margin-top: 2px;
  padding: 15px 10px;
  background-color: #fff;
  position: absolute;
  filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  border-radius: 5px;
  z-index: 1100;
}
