.companyInfo {
  margin-bottom: -8px;
}
.companyInfo li {
  line-height: 1.6;
}
.companyInfo :global(.material-icons) {
  position: relative;
  top: 2px;
}
