.container {
  position: relative;
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--gray-200);
}

.container:hover,
.container:focus {
  border: 1px solid var(--gray-300);
}

.toggle {
  width: 100%;
  border: 0;
  border-radius: 4px;
  background: transparent;
}

.icon {
  color: var(--gray-600);
}
.label {
  text-align: left;
  font-weight: 500;
}

.options {
  padding: 4px 0 0 0;
  margin: 4px 0 0 0;
  list-style: none;
}

.open {
  border-top: 1px solid var(--gray-200);
}

.option {
  padding: 1px 8px;
}

.optionCheckbox,
.optionLabel {
  cursor: pointer;
}

.optionLabel {
  position: relative;
  border-radius: 4px;
  border: 0;
  background-color: transparent;
  text-align: left;
  font-size: 14px;
  padding-right: 40px;
}

.optionLabel:hover,
.optionLabel:focus {
  background-color: var(--blue-100);
}

.optionLabel:hover:after {
  color: var(--gray-600);
  font-size: 12px;
  content: 'ONLY';
  display: inline-block;
  border-radius: 2px;
  padding: 1px;
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -10px;
}

.togglesAll:hover:after {
  content: 'ALL';
}

.showToggle:after {
  content: 'TOGGLE';
  color: var(--gray-600);
  font-size: 12px;
  display: inline-block;
  border-radius: 2px;
  padding: 1px;
  position: absolute;
  right: 4px;
  top: 50%;
  margin-top: -10px;
}

.reset {
  position: absolute;
  right: 32px;
  top: 8px;
  cursor: pointer;

  width: 24px;
  height: 24px;
  border: 0;
  border-radius: 2px;
  background-color: transparent;
}

.reset:hover,
.reset:focus {
  background-color: var(--gray-200);
}
