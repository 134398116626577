.frame {
  width: 100%;
  height: 100%;
  border: none;
}

.container {
  overflow: hidden;
  height: calc(100vh - 170px);
}
