.module-head {
  background-color: #fff;
  border-bottom: 1px solid var(--gray-300);
  padding-left: 32px;
  padding-right: 32px;
}

.module-head > .row {
  min-height: 136px;
}

.module-head .select-menu-toggle {
  width: 280px;
}

.site-search {
  max-width: 210px;
}

.site-search ::placeholder {
  font-weight: 600;
  color: var(--gray-600);
}

.site-search-input {
  padding-left: 2px;
  padding-right: 2px;
  box-sizing: border-box;
  border: 0;
  border-bottom: 2px solid transparent;

  font-size: 14px;
}

.site-search-input:focus {
  outline: none;
  border-bottom: 2px solid var(--blue-400);
}
