.loadingText {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
}
.loadingIconContainer {
  height: 70px;
  width: 70px;
  position: relative;
  margin: 0 auto 25px;
}
.nutshellLoadingIndicator {
  width: 70px;
  height: 70px;
  animation: spin 3s linear infinite;
}
.nutshellExpIcon {
  width: 30px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
}
.slideBg {
  background: #d1eaf5;
  border-radius: 300px;
  padding: 30px;
  height: 300px;
  width: 300px;
  margin: 0 auto;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingContainer {
  background-color: white;
  border-radius: 20px;
  max-width: 1058px;
}

.slideImages {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
