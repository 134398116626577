.DatePicker {
  position: relative;
  height: 42px;
}

.DatePicker__inputs {
  align-items: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  color: #ced4da;
  display: flex;
  height: 42px;
  padding-right: 0.625rem;
  overflow: hidden;
  cursor: pointer;
}

.DatePicker__Calendar-icon {
  color: #4c74b9;
  cursor: pointer;
  display: inline-block;
  padding: 0.605rem 0.525rem 0.605rem 0.725rem;
  vertical-align: middle;
}

.DatePicker__input {
  background-color: #fff;
  border: none;
  border-bottom: 2px solid #fff;
  border-radius: 0;
  color: #374046;
  font-size: 0.785rem;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.9rem 0.125rem 0.75rem;
  text-align: center;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 80px;
}

.DatePicker__input:focus {
  border: 0;
  border-bottom: 2px solid #4c74b9;
  box-shadow: none;
  outline: 0 none;
}

.DatePicker .react-date-picker__inputGroup__input {
  color: #374046;
}

.DatePicker-Dialog {
  background: #fff;
  border: 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);
  border-radius: 3px;
  display: flex;
  padding: 22px;
  position: absolute;
  left: 1px;
  top: 108%;
  width: 360px;
  z-index: 9;
}
@media only screen and (max-width: 768px) {
  .DatePicker-Dialog {
    width: 320px;
    flex-direction: column;
    padding: 15px;
  }
}

.DatePicker-Shortcuts {
  min-width: 175px;
  padding-right: 22px;
}
@media only screen and (max-width: 768px) {
  .DatePicker-Shortcuts {
    padding: 0;
    margin-bottom: 20px;
  }
}
.DatePicker-Shortcuts__Button {
  background: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  border-radius: 4px;
  color: #374046;
  display: block;
  font-size: 1rem;
  margin-bottom: 6px;
  padding: 0.705rem 1.025rem;
  text-align: left;
  width: 100%;
}
.DatePicker-Shortcuts__Button:focus,
.DatePicker-Shortcuts__Button:hover {
  border: 1px solid #4c74b9;
  color: #4c74b9;
  outline: none;
}
.DatePicker-Shortcuts__Button--active {
  border: 1px solid #4c74b9;
  color: #4c74b9;
  outline: none;
}

.DatePicker--has-shortcuts .DatePicker-Dialog {
  width: 510px;
}
@media only screen and (max-width: 768px) {
  .DatePicker--has-shortcuts .DatePicker-Dialog {
    width: 320px;
    flex-direction: column;
    padding: 15px;
  }
}

.DatePicker--doubleView .DatePicker-Dialog {
  width: 750px;
}
@media only screen and (max-width: 768px) {
  .DatePicker--doubleView .DatePicker-Dialog {
    width: 320px;
    flex-direction: column;
    padding: 15px;
  }
}

.DatePicker--doubleView .react-calendar__viewContainer > div:first-child {
  margin-right: 20px;
}
@media only screen and (max-width: 768px) {
  .DatePicker--doubleView .react-calendar__viewContainer > div:first-child {
    margin: 0 0 20px;
  }
}

.DatePicker .react-calendar__navigation {
  margin-bottom: 20px;
}
.DatePicker .react-calendar__navigation__label {
  background-color: white;
  border: 0;
  border-radius: 3px;
  font-size: 18px;
  caption-side: initial;
  font-family: Helvetica, sans-serif;
  font-weight: bold;
  margin: 0 3px;
  outline: 0;
}
.DatePicker .react-calendar__navigation__label:enabled:hover,
.DatePicker .react-calendar__navigation__label:enabled:focus {
  background-color: white;
}
.DatePicker--view .react-calendar__navigation__label {
  pointer-events: none;
}
.DatePicker .react-calendar__navigation__arrow {
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(228, 231, 231);
  border-radius: 3px;
  padding: 0.325rem 0.85rem;
}
.DatePicker .react-calendar__navigation__prev-button {
  margin-left: 3px;
}
.DatePicker .react-calendar__navigation__next-button {
  margin-right: 3px;
}

.DatePicker .react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.DatePicker .react-calendar__viewContainer {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .DatePicker .react-calendar__viewContainer {
    flex-direction: column;
  }
}
.DatePicker .react-calendar__month-view__weekdays {
  margin-bottom: 7px;
}
.DatePicker .react-calendar__month-view__weekdays__weekday {
  color: rgb(117, 117, 117);
  font-weight: normal;
  font-size: 0.85rem;
  padding: 0;
  text-transform: capitalize;
}
.DatePicker .react-calendar__month-view__days,
.DatePicker .react-calendar__year-view,
.DatePicker .react-calendar__decade-view {
  border: 0;
}
.DatePicker .react-calendar__tile {
  background-color: white;
  border: 0;
  font-size: 0.95rem;
  font-weight: 500;
  outline: 1px solid rgb(228, 231, 231) !important;
  padding: 0.55em 0;
}
.DatePicker .react-calendar__tile:focus,
.DatePicker .react-calendar__tile:hover {
  border: 0;
  box-shadow: none;
  outline: 1px solid rgb(228, 231, 231) !important;
}

.DatePicker .react-calendar__tile--now {
  background: rgba(255, 232, 188, 0.4);
}
.DatePicker .react-calendar__tile--hover {
  background-color: #eff4f9;
  color: #4c74b9;
}
.DatePicker .react-calendar__tile--active:enabled:hover,
.DatePicker .react-calendar__tile--active:enabled:focus,
.DatePicker .react-calendar__tile--hasActive:enabled:hover,
.DatePicker .react-calendar__tile--hasActive:enabled:focus {
  background-color: #4c74b9;
  outline: 1px solid #4c74b9 !important;
}
.DatePicker .react-calendar__tile--active,
.DatePicker .react-calendar__tile--hasActive {
  background: #4c74b9 !important;
  color: white;
  outline: 1px solid #4c74b9 !important;
}
