.toggle {
  background-color: white;
  color: var(--primary-dark-gray);
  border-color: var(--blue-001);
}

.toggle:hover {
  background-color: white;
  color: var(--primary-dark-gray);
  border-color: var(--blue-001);
}

.viewAsSelector {
  height: 80%;
}

.close {
  display: flex;
  align-items: center;
  color: var(--primary-dark-gray);
  cursor: pointer;

  position: absolute;
  top: 12px;
  right: 36px;
  width: 20px;
  height: 20px;
  line-height: 22px;
  padding: 0;
  border: 0;
  border-radius: 2px;
  margin-left: 10px;
  background: none;
  -webkit-appearance: none;
}

.close:hover {
  background-color: var(--gray-100);
}

.dropdownMenu {
  position: absolute;
  z-index: 1001;
}

.dropDownMenuOffset {
  left: -190px !important;
}

.menuWrapper:after,
.menuWrapper:before {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  display: inline-block;
}

.menuWrapper:before {
  top: -11px;
  right: 17px;
  border-width: 0px 10px 11px 10px;
  border-color: transparent transparent var(--gray-300) transparent;
  z-index: 2;
}

.menuWrapper:after {
  top: -9px;
  right: 18px;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent var(--white) transparent;
  z-index: 3;
}

.icon {
  font-size: 22px !important;
  padding: 11px;
  user-select: none;
}
