.combobox-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  display: inline;
}

.combobox {
  border: var(--gray-400) solid 1px;
  padding: 7px;
  display: inline-flex;
  background-color: #fff;
  border-radius: 4px;
  align-items: center;
  min-width: 250px;
}

.input {
  border: none;
  color: #374046;
  flex-grow: 1;
}

.options-wrapper {
  position: absolute;
}

.options,
.optionsSearching {
  cursor: pointer;
  padding-top: 0.25rem;
  border-radius: 4px;
  min-width: 250px;
}

.options li,
.optionsSearching {
  list-style: none;
  padding: 0.25rem 0 0.25rem 0.75rem;
  background-color: #fff;
}

.optionsSearching {
  user-select: none;
  font-size: smaller;
  cursor: default;
  color: #a9b4bc;
}

.options li:hover {
  background-color: #ebedef;
}

.combo-button {
  border: none;
  background-color: transparent;
}

.icon {
  color: #374046;
}

.custom-select-wrapper {
  position: relative;
  user-select: none;
  display: inline;
}

.custom-select {
  display: flex;
  flex-direction: column;
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
}

.custom-select .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  transition: all 0.5s;
}

.custom-option:hover {
  cursor: pointer;
  background-color: #b2b2b2;
}

.custom-option.selected {
  color: #ffffff;
  background-color: #305c91;
}
