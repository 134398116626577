.avatar {
  border: 2px solid transparent;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
}

.workstackIcon {
  font-size: 28px;
}

.miniAvatar {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 32px;
  height: 32px;
  border: 2px solid white;
}

.hoursOverlay {
  margin-top: -14px;
  box-shadow: 0px 5px 15px rgb(76 116 185 / 25%);
  padding: 2px 8px;
  z-index: 1;
}

.hoursOverlayLabel {
  font-size: 10px;
  line-height: 1em;
  margin-top: 1px;
}

.name,
.title {
  line-height: 1.2em;
}

.title {
  margin-top: 2px;
}

.smeRibbonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 5px;
  width: 30px;
  height: 30px;
  position: absolute;
  background: white;
  right: -10px;
  box-shadow: 0px 5px 15px rgba(76, 116, 185, 0.25);
}

.smeRibbon {
  width: 15px;
}

.pointer {
  cursor: pointer;
}

@media (min-width: 1600px) {
  .avatar {
    width: 80px !important;
    height: 80px !important;
  }

  .workstackIcon {
    font-size: 36px;
  }

  .miniAvatar {
    width: 38px;
    height: 38px;
  }
}
