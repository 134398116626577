.smeGrid {
  display: flex;
  flex-direction: column;
}

.smeGrid > div + div {
  margin-top: 20px;
}
.smeIcon {
  height: 48px;
  width: 48px;
}

.sidebarGraphic {
  background-image: url(/assets/images/contributors/sidebar-graphic.png);
  background-size: cover;
  background-position: center;
  border-radius: 4px;
}

@media (min-width: 1300px) {
  .sidebarGraphic {
    background-position: top;
  }
}
