.close {
  color: var(--primary-light-gray);
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
  padding-left: 10px;
}

.dropdownMenu {
  position: absolute;
  z-index: 1001;
}

.toggleLabel {
  flex-grow: 1;
  text-align: left;
  font-size: 15px;
  user-select: none;
  max-width: 116px;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
