.slideHeader {
  font-size: 32px;
  font-weight: 900;
  line-height: 39px;
}
:global(.loading-carousel, .carousel-indicators, li) {
  height: 1px;
  border-top: 1px solid transparent;
  width: 32px;
  background: #f2f2f2;
  opacity: 1;
  border-radius: 30px;
}
:global(.loading-carousel, .carousel-indicators, li.active) {
  background: #49a9d7;
}
.slideSubHeader {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #0b1720;
}

.mcfxLogo {
  height: 37px;
  position: relative;
  width: 284px;
  left: 0px;
  top: 5px;
  border-radius: 0px;
}
