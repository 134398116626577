.screenCover {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9998;
}

.overlay {
  background-color: rgb(107 114 128 / 0.75);
}

.contentContainer {
  min-height: 100%;
}

.closeBtn {
  top: 14px;
  right: 14px;
  transition: color 0.15s ease-in-out;
}
