.wrapper {
  background: linear-gradient(0deg, var(--blue-100) -7.46%, rgba(242, 248, 255, 0) 90.3%);
  padding: 23px;
  border-left: 1px solid var(--gray-200);
  border-right: 1px solid var(--gray-200);
}

.statsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 10px;
  flex-grow: 1;
  justify-items: center;
}

.stat {
  max-width: 220px;
}

.statContent {
  margin-left: 12px;
}

.partnerLogo {
  height: 52px;
}

@media (min-width: 1300px) {
  .statsGrid {
    justify-items: start;
  }
}
