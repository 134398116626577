.container :global(.ql-container) {
  font-size: 14px !important;
  border-top: none !important;
  border-radius: 0 0 3px 3px !important;
}

.container-sm :global(.ql-container) {
  min-height: 100px;
}

.container-md :global(.ql-container) {
  min-height: 300px;
}
