.header {
  min-height: 280px;
}

.topHeaderRound {
  border-radius: 8px 8px 0 0;
}

.topHeader {
  height: 155px;
  display: block;
  position: relative;
  overflow: hidden;
}

.topHeader > img {
  min-width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  filter: grayscale(100%) opacity(0.5);
  z-index: 1;
  align-self: center;
}

.overlayContent {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}

.overlayContent p {
  filter: drop-shadow(2px 4px 4px #00000040);
}

.overlayContent img {
  height: 25px;
  width: auto;
}

.shareButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}

.bottomHeader {
  height: 155px;
}

.icon {
  border-radius: 50%;
  opacity: 0.4;
  width: 30px;
  height: 30px;
}

.iconSvg {
  width: 28px;
}

@media only screen and (min-width: 1440px) {
  .topHeader {
    height: 235px;
  }
}

.container {
  width: 100px;
  height: 100px;
  border: 2px solid black;
  border-radius: 50%;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffcc00;
}

.svg {
  color: #ffcc00; /* Change the color to your desired color */
  font-size: 40px;
}
