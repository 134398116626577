.panel {
  min-width: 260px;
  border: 1px solid var(--gray-400);
  z-index: 1;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
  top: -5px;
  right: 11px;
}

[data-popper-placement='top-end'] .arrow {
  top: unset;
  bottom: -5px;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
  border-top: 1px solid var(--gray-400);
  border-left: 1px solid var(--gray-400);
}

[data-popper-placement='top-end'] .arrow::before {
  transform: rotate(-45deg);
  border-top: unset;
  border-bottom: 1px solid var(--gray-400);
}
