.menuWrapper {
  margin-top: 16px;
  width: 300px;
}

.menuWrapper:after,
.menuWrapper:before {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  content: '';
  display: inline-block;
}

.menuWrapper:before {
  top: -11px;
  right: 17px;
  border-width: 0px 10px 11px 10px;
  border-color: transparent transparent #dce1e4 transparent;
  z-index: 2;
}

.menuWrapper:after {
  top: -9px;
  right: 18px;
  border-width: 0 9px 9px 9px;
  border-color: transparent transparent #fff transparent;
  z-index: 3;
}

.arrow {
  border: 0;
  background-color: transparent;
}

.yearBadge {
  border: 0;
  padding: 8px 9px;
  border-radius: 8px;
}

.quarter {
  height: 60px;
  border-radius: 8px;
}

.gotoButton {
  width: 86px;
  height: 32px;
}

.popoverDisplay {
  width: 108px;
}

.arrow:disabled,
.yearBadge:disabled,
.quarter:disabled,
.arrow:disabled:hover,
.yearBadge:disabled:hover,
.quarter:disabled:hover {
  color: var(--gray-300);
}
