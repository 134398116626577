.wrapper {
  border: 1px solid var(--blue-001);
}

.title {
  gap: 8px 12px;
}

.teamName {
  line-height: 1.2;
}

.avatarGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 120px);
  justify-content: center;
  gap: 8px 14px;
  flex-grow: 1;
}

.avatarGridWide {
  grid-template-columns: repeat(auto-fit, 150px);
}

.noResults {
  max-width: 550px;
}

.noResults > span {
  font-size: 52px;
}

@media (min-width: 1300px) {
  .avatarGrid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .avatarGridWide {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}
