.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.4s ease, border-bottom 0.4s ease, color 0.4s ease;
}

.wrapper.dark {
  color: var(--white);
  background-color: #152035;
  border-bottom: 1px solid #263a5f;
}

.wrapper.light {
  color: #152035;
  background-color: var(--blue-00);
  border-bottom: 1px solid var(--gray-400);
}

.children {
  display: flex;
  justify-content: space-between;
  width: 84%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
