.slidePane :global(.modal-dialog) {
  margin: auto;
  width: 500px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
  position: fixed;
}

.slidePane.right :global(.modal-dialog) {
  right: 0;
}

.slidePane.left :global(.modal-dialog) {
  left: 0;
}

.slidePane :global(.modal-content) {
  height: 100%;
  border-radius: 0px !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.slidePane.right :global(.modal-content) {
  border-right: 0 !important;
}

.slidePane.left :global(.modal-content) {
  border-left: 0 !important;
}

.slidePane :global(.modal-body) {
  padding: 15px 15px 80px;
  overflow-y: auto;
}

.slidePane::-webkit-scrollbar {
  width: 6px;
}

.slidePane::-webkit-scrollbar-track {
  background: #efefef;
}

.slidePane::-webkit-scrollbar-thumb {
  background: #abb9c2;
  border-radius: 8px;
}

.slidePane::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
  border-radius: 8px;
}

.lg {
  width: auto !important;
  max-width: none !important;
}
