.triggerIcon {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9999;
  border: 2px solid var(--gray-400);
}
.triggerIcon.active {
  border-color: transparent;
}

.header {
  top: 0;
  background: linear-gradient(0deg, var(--blue-600) 0%, var(--blue-700) 100%);
  z-index: 1;
}

.content {
  word-break: break-all;
}

.checkbox {
  min-height: auto;
}
.checkbox input:checked ~ .checkboxLabel::before {
  background-color: var(--blue-vivid-300);
}
.checkbox:hover .checkboxLabel::before {
  border-color: var(--white);
}
.checkboxLabel::before,
.checkboxLabel::after {
  top: 0.1rem;
}

.favoriteIcon {
  transition: visibility 0s, opacity 0.5s;
}
.favoriteInactive {
  visibility: hidden;
  opacity: 0;
}
.favoriteActive {
  visibility: visible;
  opacity: 1;
  color: var(--red-04);
}
.favoriteWrapper:hover .favoriteInactive {
  visibility: visible;
  opacity: 1;
  color: var(--red-03);
}
.favoriteClicked {
  animation-name: pulse;
  animation-duration: 2s;
}
@keyframes pulse {
  0% {
    transform: scale(0.97);
  }
  20% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.2);
  }
  60% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.toggle button,
.toggle label > span {
  transition: all 0.15s ease-in-out;
}
.toggle button {
  border: 2px solid transparent;
}
.toggle:hover button {
  border: 2px solid var(--gray-500);
}
.toggle.checked:hover button {
  border: 2px solid var(--blue-300);
}
