.container {
  position: fixed;
  top: 70px;
  right: 0;
  z-index: 1000;
  min-width: 25%;
  height: calc(100vh - 70px);
  padding: 24px;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.15);
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 0.25);
  font-size: 18px;
  line-height: 32px;
  text-align: center;
}

.close:hover {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.05);
}

.header {
  width: 100%;
  padding: 24px;
  /* margin-left: -24px; */
  margin-right: -24px;
  background: #f9fbff;
}
